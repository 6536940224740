import { Descendant } from 'slate';
import { create, StateCreator } from 'zustand';
import {
  StepKey,
  Communication,
  CommunicationType,
  BriefData,
  PersonalisedCommunication,
  CommunicationBeingSaved,
} from '../types';

export type ContentCreationState = {
  activeStep: StepKey;
  communicationType?: CommunicationType;
  template?: string;

  /** @deprecated */
  briefData?: BriefData;
  /** @deprecated */
  communication?: Communication;
  /** @deprecated */
  selectedPersonas: string[];
  /** @deprecated */
  draftToBeSaved: Descendant[] | undefined;
  /** @deprecated */
  communicationsBeingSaved: CommunicationBeingSaved[];
  /** @deprecated */
  savingError: boolean;
};

const initalState: ContentCreationState = {
  activeStep: 'aimSelection' as StepKey,
  communicationType: undefined,
  template: undefined,
  briefData: undefined,
  communication: undefined,
  selectedPersonas: [],
  draftToBeSaved: undefined,
  communicationsBeingSaved: [],
  savingError: false,
};

export type ContentCreationActions = {
  setActiveStep: (activeStep: StepKey) => void;
  setCommunicationType: (communicationType: CommunicationType) => void;
  setTemplate: (template: string) => void;
  resetState: () => void;

  /** @deprecated */
  setCommunication: (communication: Communication) => void;
  /** @deprecated */
  setDraft: (draft: string | null) => void;
  /** @deprecated */
  setPersonalisedCommunications: (
    personalisedCommunications: PersonalisedCommunication[],
  ) => void;
  /** @deprecated */
  updatePersonalisedCommunication: (
    id: string,
    personalisedCommunicationDraft: string,
  ) => void;
  /** @deprecated */
  setBriefData: (briefData: BriefData) => void;
  /** @deprecated */
  setSelectedPersonas: (personas: string[]) => void;
  /** @deprecated */
  setEditingViewData: (communication: Communication) => void;
  /** @deprecated */
  resetEditingViewData: () => void;
  /** @deprecated */
  setDraftToBeSaved: (editSaved: Descendant[] | undefined) => void;
  /** @deprecated */
  setCommunicationsBeingSaved: (
    personalisedCommunications: CommunicationBeingSaved[],
  ) => void;
  /** @deprecated */
  removeCommunicationBeingSaved: (id: string) => void;
  /** @deprecated */
  addCommunicationBeingSaved: (
    communicationBeingSaved: CommunicationBeingSaved,
  ) => void;
  /** @deprecated */
  setSavingError: (savingError: boolean) => void;
};

export type ContentCreationSlice = ContentCreationState &
  ContentCreationActions;

const createContentCreationSlice: StateCreator<
  ContentCreationSlice,
  [],
  [],
  ContentCreationSlice
> = (set) => ({
  ...initalState,
  setActiveStep: (activeStep: StepKey) => {
    set((state) => ({ ...state, activeStep }));
  },
  setCommunicationType: (communicationType: CommunicationType) => {
    set((state) => {
      return {
        ...state,
        communicationType,
      };
    });
  },
  setTemplate: (template: string) => {
    set((state) => ({ ...state, template }));
  },
  setBriefData: (briefData: BriefData) => {
    set({ briefData });
  },
  setCommunication: (communication: Communication) => {
    set({ communication });
  },
  setSelectedPersonas: (selectedPersonas: string[]) => {
    set({ selectedPersonas });
  },
  setDraft: (draft: string | null) => {
    set((state) => {
      if (state.communication) {
        return { ...state, communication: { ...state.communication, draft } };
      }
      return state;
    });
  },
  setPersonalisedCommunications: (
    personalisedCommunications: PersonalisedCommunication[],
  ) => {
    set((state) => {
      if (state.communication) {
        return {
          ...state,
          communication: {
            ...state.communication,
            personalisedCommunications: personalisedCommunications,
          },
        };
      }
      return state;
    });
  },
  updatePersonalisedCommunication: (
    id: string,
    personalisedCommunicationDraft: string,
  ) => {
    set((state) => {
      if (state.communication) {
        return {
          ...state,
          communication: {
            ...state.communication,
            personalisedCommunications:
              state.communication.personalisedCommunications?.map((pc) =>
                pc.id === id
                  ? { ...pc, draft: personalisedCommunicationDraft }
                  : pc,
              ),
          },
        };
      }
      return state;
    });
  },
  resetState: () => {
    set({ ...initalState });
  },
  setEditingViewData: (communication) => {
    const { type, templateId, ...rest } = communication.details;
    set({
      communicationType: type,
      template: templateId,
      briefData: {
        name: communication.name,
        ...rest,
        personaId: '', // FIXME: what is this?
      },
      communication: communication,
      selectedPersonas:
        communication.personalisedCommunications?.map((v) => v.personaId) || [],
    });
  },
  resetEditingViewData: () => {
    set({
      communicationType: undefined,
      template: undefined,
      briefData: undefined,
      communication: undefined,
      selectedPersonas: [],
    });
  },
  setDraftToBeSaved: (draftToBeSaved: Descendant[] | undefined) => {
    set({ draftToBeSaved });
  },
  setCommunicationsBeingSaved: (
    communicationsBeingSaved: CommunicationBeingSaved[],
  ) => {
    set({ communicationsBeingSaved: communicationsBeingSaved });
  },
  removeCommunicationBeingSaved: (id: string) => {
    set((state) => ({
      communicationsBeingSaved: state.communicationsBeingSaved.filter(
        (cbs) => cbs.id !== id,
      ),
    }));
  },
  addCommunicationBeingSaved: (
    communicationBeingSaved: CommunicationBeingSaved,
  ) => {
    set((state) => {
      const existingIndex = state.communicationsBeingSaved.findIndex(
        (cbs) => cbs.id === communicationBeingSaved.id,
      );

      if (existingIndex !== -1) {
        state.communicationsBeingSaved[existingIndex].content =
          communicationBeingSaved.content;
        return {
          ...state,
          communicationsBeingSaved: [...state.communicationsBeingSaved],
        };
      } else
        return {
          ...state,
          communicationsBeingSaved: [
            ...state.communicationsBeingSaved,
            communicationBeingSaved,
          ],
        };
    });
  },
  setSavingError: (savingError: boolean) => {
    set({ savingError });
  },
});

export const useContentCreationSlice = create<ContentCreationSlice>((...a) => ({
  ...createContentCreationSlice(...a),
}));

/** @deprecated */
export function setEditingViewData(communication: Communication) {
  useContentCreationSlice.getState().setEditingViewData(communication);
}

/** @deprecated */
export function resetEditingViewData() {
  useContentCreationSlice.getState().resetEditingViewData();
}

/** @deprecated */
export function resetState() {
  useContentCreationSlice.getState().resetState();
}
