import { useForm } from '@xtreamsrl/react-forms';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useCreateCommunicationMutation } from '../mutations/useCreateCommMutation.ts';
import { useRecreateCommMutation } from '../mutations/useRecreateCommMutation.ts';
import { useContentCreationSlice } from '../slice/contentCreationSlice.ts';
import {
  BriefDEMFormFields,
  BriefData,
  Communication,
  Persona,
} from '../types';
import { useBriefData } from './useBriefData.ts';
import { useCommunicationSetter } from './useCommunicationSetter.ts';
import { useCommunicationType } from './useCommunicationType.ts';
import { useTemplateId } from './useTemplateId.ts';

const emptyValues: BriefDEMFormFields = {
  name: '',
  description: '',
  plusOrIncentives: '',
  personaId: '',
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('brief.error.name')
    .max(40, 'brief.error.tooLongName'),
  description: yup.string().required('brief.error.description'),
  plusOrIncentives: yup.string(),
  personaId: yup.string().required('brief.error.personaId'),
});

function isBriefDemForm(object: BriefData): object is BriefDEMFormFields {
  return (
    'name' in object &&
    typeof object.name === 'string' &&
    'description' in object &&
    typeof object.description === 'string' &&
    'plusOrIncentives' in object &&
    typeof object.plusOrIncentives === 'string' &&
    'personaId' in object &&
    typeof object.personaId === 'string'
  );
}

export function useCreateCommunication({
  onSuccess,
  personas,
}: {
  onSuccess: () => void;
  personas: Persona[];
}) {
  const communicationType = useCommunicationType();
  const templateId = useTemplateId();

  if (!communicationType) throw new Error('Communication type is required');

  // this will have to depend on the communication type when there will be the introduction of a communication type that doesn't require a template
  if (!templateId)
    throw new Error('Missing template for communication creation.');

  const [initialBriefData, setBriefData] = useBriefData();
  if (initialBriefData && !isBriefDemForm(initialBriefData))
    throw new Error('Invalid initial data for DEM communication');

  // Always set the default persona as the initial value
  const initialValues = useMemo(() => {
    const defaultPersona = personas.find((persona) => persona.default);
    const briefData = initialBriefData || emptyValues;
    return {
      ...briefData,
      personaId: briefData.personaId || defaultPersona?.id || '',
    };
  }, [initialBriefData, personas]);

  const setCommunication = useCommunicationSetter();
  const { mutate: create, isPending: isCreating } =
    useCreateCommunicationMutation();

  const { mutate: regenerate, isPending: isRegenerating } =
    useRecreateCommMutation();

  const communication = useContentCreationSlice((state) => state.communication);
  const isPending = communication ? isRegenerating : isCreating;

  const form = useForm({
    initialValues,
    validationSchema,
  });

  const [showModal, setShowModal] = useState<
    'CONFIRMATION' | 'FEEDBACK' | undefined
  >(undefined);

  function executeOnSuccess(data: Communication) {
    form.formProps.reset({
      name: data.name,
      description: data.details.description,
      plusOrIncentives: data.details.plusOrIncentives,
    });
    setBriefData({
      name: data.name,
      description: data.details.description,
      plusOrIncentives: data.details.plusOrIncentives,
      personaId: data.personaDrafts[0].personaId,
    });
    setCommunication(data);
    onSuccess();
  }

  const submitCreation = form.formProps.handleSubmit(
    (values: BriefData, event) => {
      event?.preventDefault();
      useContentCreationSlice.setState({ briefData: values });
      create(
        {
          communicationType,
          values: { templateId, ...values },
        },
        {
          onError: () => {
            setShowModal('FEEDBACK');
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submitRegeneration = form.formProps.handleSubmit(
    (values: BriefData, event) => {
      event?.preventDefault();
      useContentCreationSlice.setState({ briefData: values });
      const commId = communication!.id;
      regenerate(
        {
          commId,
          input: {
            name: values.name,
            personaId: values.personaId,
            details: {
              type: communicationType,
              description: values.description,
              plusOrIncentives: values.plusOrIncentives,
              templateId,
            },
          },
        },
        {
          onError: () => {
            setShowModal('FEEDBACK');
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submit = communication?.id ? submitRegeneration : submitCreation;

  return {
    initialValues,
    isNewCommunication: !communication,
    showModal,
    setShowModal,
    form,
    submit,
    isPending,
  };
}
