import { useMemo } from 'react';
import { Descendant, Editor } from 'slate';
import { Editable, Slate } from 'slate-react';
import {
  instantiateEditor,
  processor,
  renderElement,
  renderLeaf,
} from '../../_shared/utils/slate';
import { EditorWrapper } from './EditorWrapper';

export function MarkdownViewer({
  contentToDisplay,
  paddingX,
  paddingY,
}: {
  contentToDisplay: string | Descendant[];
  paddingX?: string;
  paddingY?: string;
}) {
  const descendantToDisplay =
    typeof contentToDisplay === 'string'
      ? (processor.processSync(contentToDisplay).result as Descendant[])
      : contentToDisplay;

  const editor = useMemo(() => instantiateEditor(), []);

  const slateValue = useMemo(() => {
    editor.children = descendantToDisplay;
    // this inserts text nodes when empty to avoid rendering error
    Editor.normalize(editor, { force: true });
    return editor.children;
  }, [editor, descendantToDisplay]);

  return (
    <Slate editor={editor} initialValue={slateValue}>
      <EditorWrapper paddingX={paddingX} paddingY={paddingY}>
        <Editable
          className="markdown-content"
          readOnly={true}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          disableDefaultStyles={false}
        />
      </EditorWrapper>
    </Slate>
  );
}
