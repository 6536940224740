// TODO: Implement this API call and properly type the response Promise<...>

import { Communication, CommunicationType } from '../types';
import { default as fetch } from '../../_shared/fetch';

export type CreateCommunicationInput = {
  name: string;
  personaId: string;
  details: {
    type: CommunicationType;
    description: string;
    plusOrIncentives?: string;
    templateId: string;
  };
};

type CreateCommunicationInputOld = {
  communicationType: CommunicationType;
  values: Record<string, string | undefined>;
};

export async function createCommunicationAPI(
  input: CreateCommunicationInput,
): Promise<Communication>;

/** @deprecated */
export async function createCommunicationAPI(
  input: CreateCommunicationInputOld,
): Promise<Communication>;

export async function createCommunicationAPI(
  input: CreateCommunicationInput | CreateCommunicationInputOld,
): Promise<Communication> {
  let payload: CreateCommunicationInput;

  if ('communicationType' in input) {
    const { communicationType, values } = input;
    payload = {
      name: values.name!,
      personaId: values.personaId!,
      details: {
        type: communicationType,
        description: values.description!,
        plusOrIncentives: values.plusOrIncentives!,
        templateId: values.templateId!,
      },
    };
  } else {
    payload = input;
  }
  const communication: Communication = await fetch.post(
    '/communications',
    payload,
  );
  return communication;
}
