import { useQuery } from '@tanstack/react-query';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useEffect } from 'react';
import { getCommPersonaDraftQuery } from 'src/contentCreation/queries/useCommPersonaDraftQuery.ts';
import { MarkdownViewer } from '../MarkdownViewer.tsx';
import { MarkdownViewerHeader } from '../MarkdownViewerHeader.tsx';

export function PersonaDraftStream({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  const queryOptions = getCommPersonaDraftQuery({ commId, draftId });
  const { data: personaDraft } = useQuery({
    ...queryOptions,
    staleTime: Infinity, // Never refetch
    select: (data) => data?.draft,
  });

  useEffect(() => {
    // If the user refreshes the page during the generation process the page will be blank.
    // This is a workaround to alert the user before they leave the page.
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <Flex direction="column" height="100%" backgroundColor="grey.1">
      <MarkdownViewerHeader isLoading />
      <MarkdownViewer contentToDisplay={personaDraft ?? ''} />
    </Flex>
  );
}
