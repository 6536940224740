import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { styled } from '@xtreamsrl/react-ui-kit/styles';

/**
 * Use this to wrap slate-react's `Editable` component.
 * It just adds the necessary padding and overflow styles.
 */
export const EditorWrapper = styled(Box)(({ theme }) => ({
  paddingBlock: theme.spacing('sm-6'),
  paddingInline: theme.spacing('md-5'),
  overflow: 'auto',
}));
