import { useMutation, useQueryClient } from '@tanstack/react-query';
import { default as fetch } from 'src/_shared/fetch';
import { commPersonaDraftQueryKeys } from '../queries/useCommPersonaDraftQuery';
import { PersonaDraft } from '../types';

async function generatePersonaDraft(
  {
    commId,
    draftId,
  }: {
    commId: string;
    draftId: string;
  },
  signal?: AbortSignal,
) {
  const response = await fetch.get<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/content`,
    {}, // query params
    false, // extractJson
    signal,
  );
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  const stream = response.body;
  if (!stream) {
    throw new Error('No content stream');
  }
  return stream;
}

type ChunkType =
  | { delta: string }
  | { id: string; message: string; code: string };

export function useGeneratePersonaDraftMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: generatePersonaDraft,
    async onSuccess(stream, { commId, draftId }) {
      try {
        for await (const chunk of readStream(stream)) {
          // Update the draft in the cache on each chunk
          queryClient.setQueryData<PersonaDraft>(
            commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
            (oldData) => {
              if (!oldData) return oldData;
              const oldDraft = oldData.draft ?? '';
              return {
                ...oldData,
                draft: oldDraft + chunk,
              };
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
      // Invalidate the query to refetch the draft
      await queryClient.invalidateQueries({
        queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
      });
    },
  });
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  // All transformers should be defined inside this function scope
  // to make sure that they are not shared between multiple runs

  const jsonParser = new TransformStream<string, unknown[]>({
    transform(chunk, controller) {
      // Chunks could be buffered, so we split by newline
      const lines = chunk.split('\n').filter(Boolean);
      const parsed = lines.map((line): unknown => JSON.parse(line));
      controller.enqueue(parsed);
    },
  });

  // To reduce re-renders, we can aggregate the delta chunks
  const deltaReducer = new TransformStream<unknown[], string>({
    transform(chunk, controller) {
      const values = chunk as ChunkType[];

      let delta = '';
      for (const value of values) {
        if ('delta' in value) {
          delta += value.delta;
        } else {
          // leave immediately on error
          controller.error(value);
          return;
        }
      }

      controller.enqueue(delta);
    },
  });

  const reader = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(jsonParser)
    .pipeThrough(deltaReducer)
    .getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    yield value;
  }
}
