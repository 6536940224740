import { useTranslate } from '@xtreamsrl/react-i18n';
import { EditorHeader } from './EditorHeader';
import { EditorStatus } from './EditorStatus';
import { TranslationKeys } from 'src/_shared/assets/i18n';

export function MarkdownViewerHeader({ isLoading }: { isLoading: boolean }) {
  const t = useTranslate();
  return (
    <EditorHeader>
      <EditorStatus pinColor="brand.6">
        {isLoading
          ? t('commEditing.status.loading' satisfies TranslationKeys)
          : t('commEditing.status.loaded' satisfies TranslationKeys)}
      </EditorStatus>
    </EditorHeader>
  );
}
