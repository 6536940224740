import fetch from '../../_shared/fetch';
import { PersonaDraft } from '../types';

export async function createPersonaDraft({
  personaId,
  commId,
  name,
  directions,
}: {
  personaId: string;
  commId: string;
  name?: string;
  directions?: string;
}) {
  const result: PersonaDraft = await fetch.post(
    `/communications/${commId}/persona-drafts`,
    {
      personaId,
      name,
      directions: directions || undefined,
    },
    {
      extractJson: true,
    },
  );
  return result;
}
