import { useTranslate } from '@xtreamsrl/react-i18n';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { FormEventHandler, useCallback, useMemo, useState } from 'react';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { useCommunication } from '../hooks/useCommunication';
import { useCreatePersonaDraftMutation } from '../mutations/useCreatePersonaDraftMutation';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';
import { Persona, PersonaDraft } from '../types';
import {
  CommEditingModal,
  PersonaSelector,
  PromptInput,
  SubmitButton,
} from './CommEditingModal';

export function CommEditingNewDraft({
  onDraftCreated,
}: {
  onDraftCreated: (draft: PersonaDraft) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpen = useCallback(() => setModalIsOpen(true), []);
  const handleClose = useCallback(() => setModalIsOpen(false), []);

  const handleNewDraft = useCallback(
    (draft: PersonaDraft) => {
      handleClose();
      onDraftCreated(draft);
    },
    [onDraftCreated, handleClose],
  );

  return (
    <>
      <Button
        variant="outlined"
        leftIcon={<Icon name="Sparkles" />}
        onClick={handleOpen}
        style={{ width: 'max-content', margin: '0 auto' }}
      >
        Crea nuova versione
      </Button>
      <NewDraftModal
        isOpen={modalIsOpen}
        onDraftCreated={handleNewDraft}
        onClose={handleClose}
      />
    </>
  );
}

function NewDraftModal({
  isOpen,
  onClose,
  onDraftCreated,
}: {
  isOpen: boolean;
  onDraftCreated: (draft: PersonaDraft) => void;
  onClose: () => void;
}) {
  const t = useTranslate();

  const { data: personas = [] } = usePersonaListQuery();
  return (
    <CommEditingModal
      iconName="Sparkles"
      isOpen={isOpen}
      onClose={onClose}
      title={t('commEditing.newDraftModal.title' satisfies TranslationKeys)}
      subtitle={t(
        'commEditing.newDraftModal.subtitle' satisfies TranslationKeys,
      )}
      paragraph={t(
        'commEditing.newDraftModal.paragraph' satisfies TranslationKeys,
      )}
    >
      {personas.length > 0 ? (
        <NewDraftForm onDraftCreated={onDraftCreated} personas={personas} />
      ) : (
        <p>Loading...</p>
      )}
    </CommEditingModal>
  );
}

function NewDraftForm({
  onDraftCreated,
  personas,
}: {
  onDraftCreated: (draft: PersonaDraft) => void;
  personas: Persona[];
}) {
  const initialPersona = useMemo(
    () => personas.find((persona) => persona.default),
    [personas],
  );
  const [persona, setPersona] = useState(initialPersona);
  const [prompt, setPrompt] = useState('');
  const t = useTranslate();
  const { id: commId } = useCommunication();

  const { mutate: createDraft, isPending: isCreating } =
    useCreatePersonaDraftMutation();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      if (!persona) return;
      createDraft(
        {
          personaId: persona.id,
          directions: prompt,
          commId,
        },
        {
          onSuccess: onDraftCreated,
        },
      );
    },
    [persona, prompt, commId, createDraft, onDraftCreated],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PersonaSelector
        label={t(
          'commEditing.newDraftModal.form.personaSelector.label' satisfies TranslationKeys,
        )}
        selectedPersona={persona}
        setSelectedPersona={setPersona}
      />
      <PromptInput
        label={t(
          'commEditing.newDraftModal.form.promptInput.label' satisfies TranslationKeys,
        )}
        prompt={prompt}
        setPrompt={setPrompt}
      />
      <SubmitButton isLoading={isCreating}>
        {t(
          'commEditing.newDraftModal.form.submitButton.label' satisfies TranslationKeys,
        )}
      </SubmitButton>
    </form>
  );
}
